import React from 'react'
import CustomTable from '../CustomTable'
import axios from 'axios'
import { GET_ALL_CONTACTS } from '../ApiPaths'
import { useDispatch, useSelector } from 'react-redux'

const Contacts = () => {
  const dispatch=useDispatch()
  // const store=useSelector((state)=>)

  const postContact=async ()=>{
    try{
      const res= await axios.get(GET_ALL_CONTACTS)

    }catch(err){

    }
  }
  return (
    <>
      <CustomTable/>
    </>
  )
}

export default Contacts