import { Box } from "@mui/material";
import React from "react";
import {
  addData,
  clearPrimaryData,
} from "../redux/reducers/ChangePasswordSlice";
import { addData as toasterAddData } from "../redux/reducers/ToasterSlice";

import { useDispatch, useSelector } from "react-redux";
import ComponentsByType from "../ComponentsByType";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../redux/reducers/LoaderSlice";
import { Get, Post } from "../service/api.service";
import * as _ from "lodash";
const ChangePassword = () => {
  const store = useSelector((state) => state.changePassword.changePasswordJson);
  const userStore = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (buttonId) => {
    if (buttonId === "updatePassword") {
      updatePassword();
    }
  };
  const updatePassword = async () => {
    const userEmail = userStore?.email;
    const isOtpVerified = userStore?.isOtpVerified;
    if (userEmail && isOtpVerified) {
      dispatch(setLoading({ value: true }));
      const otpMode = "email";
      let obj = {
        type: otpMode,
      };
      const respVal = userEmail;
      obj[`${otpMode}`] = respVal;
      store?.primaryData?.map((s) => {
        obj[`${s.field}`] = s.response;
      });
      const resp = await Post("authentication/changePassword", obj);
      if (resp?.success) {
        dispatch(setLoading({ value: false }));
        dispatch(clearPrimaryData({}));
        navigate("/login");
        console.log(`Password changed succesfully`);
      } else {
        dispatch(setLoading({ value: false }));
        dispatch(
          toasterAddData({
            open: true,
            severity: "error",
            message: resp?.message,
          })
        );
      }
    } else {
      navigate("/forgotPassword");
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 2,
        }}
      >
        {store?.primaryData?.map((field) => (
          <ComponentsByType
            field={field}
            id={field.id}
            addData={addData}
            storeKey={"changePassword.changePasswordJson"}
            fieldKey={field.id}
            handleSubmit={handleSubmit}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ChangePassword;
