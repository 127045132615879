import { createSlice } from "@reduxjs/toolkit";
import { OtpJson } from "../../config/OtpConfig";
import * as _ from "lodash";

const initialState = {
  OtpJson: OtpJson,
};

const OtpSlice = createSlice({
  name: "otpPage",
  initialState,
  reducers: {
    addData: (state, action) => {
      let { key, value, field, id, label } = action.payload;
      if (!key) {
        key = label;
      }
      if (id !== undefined) {
        if (key) {
          //update only specific key
          state.OtpJson.primaryData[id][key] = value;
        } else {
          //to update complete obj if no key specified
          // state.LoginJson.primaryData[id] = {...value,...state.LoginJson.primaryData[id]};
          state.OtpJson.primaryData[id] = _.mergeWith(
            state.OtpJson.primaryData[id],
            value
          );
        }
      } else if (field) {
        state.OtpJson.primaryData.map((d) => {
          if (d.field === field) {
            if (key) {
              //update only specific key
              d[key] = value;
            } else {
              //to update complete obj if no key specified
              // d = {...value,...d}
              d = _.mergeWith(d, value);
            }
          }
        });
      }
    },
    //TODO :  later change this to update all types of data currently only object is supported
    updateSecData: (state, action) => {
      let { key, value } = action.payload;
      state.OtpJson.secData[key] = value;
    },
    //This is to clear form data
    clearPrimaryData: (state, action) => {
      state.OtpJson.primaryData = OtpJson.primaryData;
    },
  },
});

export const { addData, updateSecData, clearPrimaryData } = OtpSlice.actions;
export default OtpSlice.reducer;
