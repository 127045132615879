import { IconButton, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { validator } from "./GlobalFunctions";

import buttonMapper from "./ButtonMap";
import * as _ from "lodash";

const CustomTextField = (props) => {
  const { addData, storeKey, field, id, fieldKey } = props;
  const dispatch = useDispatch();
  const store = useSelector((state) => _.get(state, storeKey));

  const InputPropsJson = {
    password: {
      endAdornment: (
        <IconButton
          onClick={() => {
            UpdateData(id, "extraResponse", !field.extraResponse);
          }}
        >
          {!field.extraResponse ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      ),
    },
  };

  const UpdateData = (id, label, value) => {
    // console.log(id,label,value)
    if (label === "extraResponse") {
      dispatch(
        addData({
          id,
          // value:uObj
          label,
          value,
        })
      );
    } else {
      let validatorResp = validator(value, field.validation);
      if (field.compareWith) {
        const fieldC = field.fieldToCheck;
        const fCheckVal = _.find(store?.primaryData, {
          field: fieldC,
        })?.response;
        if (value != fCheckVal) {
          validatorResp = "Password And Confirm Password Should Match";
        }
      }
      const isValidInputField = validatorResp ? false : true;
      let uObj = {
        // response : value,
        errorMsg: validatorResp || "",
        isValidInputField: isValidInputField,
      };
      uObj[label] = value;
      dispatch(
        addData({
          id,
          value: uObj,
          // label:'response',
          // value
        })
      );
    }
  };
  const validateButtons = (field) => {
    if (field.buttonsToValidate) {
      field.buttonsToValidate.map((btn) => {
        let isButtonDisabled = false;

        const f = buttonMapper[btn];
        f.map((ff) => {
          if (ff.type === "field") {
            store.primaryData.map((sf) => {
              if (sf.field === ff.value) {
                if (!sf.isValidInputField) {
                  isButtonDisabled = true;
                }
              }
            });
          } else if (ff.type === "stateVariable") {
            const v = store.secData?.[ff.value];
            if (!v) {
              isButtonDisabled = true;
            }
          }
        });
        // console.log(`button ---> ${btn}----> status ${isButtonDisabled}`);
        const title = !isButtonDisabled ? "" : undefined;

        const uObj = {
          isButtonDisabled,
          title,
        };
        // console.log(`Update ob for ${btn}`, uObj);
        dispatch(
          addData({
            field: btn,
            value: uObj,
          })
        );
      });
    }
  };

  useEffect(() => {
    // console.log("use Effect triggered indicates store object changed",props.props.field);
    validateButtons(field);
  }, [field.isValidInputField, fieldKey]);
  return (
    <TextField
      value={field.response}
      label={field.label}
      type={!field.extraResponse ? "text" : field.type ? field.type : "text"}
      placeholder={field.placeholder}
      size="medium"
      variant="standard"
      error={!!field.errorMsg}
      helperText={field.errorMsg}
      required={field.required}
      onChange={(e) => {
        UpdateData(id, "response", e.target.value);
      }}
      InputProps={field.inputProps ? InputPropsJson[field.inputProps] : {}}
    />
  );
};

export default CustomTextField;
