import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./LoginPage";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import HomePage from "./HomePage";
import SignUpPage from "./SignUpPage";
import Toaster from "./Toaster";
import OTP from "./forget Password/OTP";

import Loader from "./Loader";
import Abc from "./config/Abc";
import Contacts from "./config/Contacts";
import NavParent from "./NavParent";
import EmailPage from "./forget Password/EmailPage";
import ChangePassword from "./forget Password/ChangePassword";


const Paths = () => {
  return (
    <Provider store={store}>
      <Loader />
      <BrowserRouter>
        <Toaster />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />

          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/forgotpassword" element={<EmailPage />} />
          <Route path="/verifyOtp" element={<OTP />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/navbar/" element={<NavParent />}>
            <Route index path="abc" element={<Abc />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="home" element={<HomePage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default Paths;
