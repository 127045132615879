export const EmailPageJson = {
  secData: {},
  primaryData: [
    {
      inputType: "typography",
      style: { textAlign: "center" },
      label: "Send Code",
      id: 0,
    },
    {
      label: "Email",
      placeholder: "Enter your Email",
      response: "",
      size: "medium",
      type: "text",
      extraResponse: "",
      errorMsg: "",
      inputProps: "",
      required: true,
      inputType: "customTextField",
      isValidInputField: false,
      buttonsToValidate: ["sendCode"],
      field: "email",
      id: 1,
      validation: {
        pattern: {
          value: "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
          message: "Enter a valid Email",
        },
        length: {
          value: 0,
          message: "email should be atleast 3 characters",
        },
      },
    },
    {
      label: "Send Code",
      field: "sendCode",
      variant: "contained",
      buttonId: "sendCode",
      type: "button",
      title: "Enter Email To Send Code",
      style: {
        "&.Mui-disabled": {
          pointerEvents: "all",
        },
      },
      inputType: "button",
      isButtonDisabled: true,
      id: 2,
    },
  ],
};
