import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
export default function Loader(props) {
  const store = useSelector((state) => state.loader);
  console.log(store);
  const open = store.loadingStatus;
  return open ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(255, 255, 255, 0.7)",
        zIndex: 9999,
      }}
    >
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  ) : null;
}
