import { MuiOtpInput } from "mui-one-time-password-input";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validatechar, validator } from "./GlobalFunctions";
import buttonMapper from "./ButtonMap";
import * as _ from "lodash";
const CustomOtp = (props) => {
  const { storeKey, addData, field, id } = props;
  const dispatch = useDispatch();
  const store = useSelector((state) => _.get(state, storeKey));
  const updateData = (id, label, value) => {
    const validatorResp = validator(value, field.validation);
    const isValidInputField = validatorResp ? false : true;
    const uObj = {
      response: value,
      errorMsg: validatorResp || "",
      isValidInputField,
    };
    dispatch(
      addData({
        id,
        value: uObj,
      })
    );
  };
  const validateButtons = (field) => {
    if (field.buttonsToValidate) {
      field.buttonsToValidate.map((btn) => {
        let isButtonDisabled = false;

        const f = buttonMapper[btn];
        f.map((ff) => {
          if (ff.type === "field") {
            store.primaryData.map((sf) => {
              if (sf.field === ff.value) {
                if (!sf.isValidInputField) {
                  isButtonDisabled = true;
                }
              }
            });
          } else if (ff.type === "stateVariable") {
            const v = store.secData?.[ff.value];
            if (!v) {
              isButtonDisabled = true;
            }
          }
        });
        // console.log(`button ---> ${btn}----> status ${isButtonDisabled}`);
        const title = !isButtonDisabled ? "" : undefined;

        const uObj = {
          isButtonDisabled,
          title,
        };
        // console.log(`Update ob for ${btn}`, uObj);
        dispatch(
          addData({
            field: btn,
            value: uObj,
          })
        );
      });
    }
  };

  useEffect(() => {
    validateButtons(field);
  }, [store]);

  return (
    <>
      <MuiOtpInput
        onComplete={() => {}}
        validateChar={validatechar}
        autoFocus={field.focus}
        length={field.length}
        value={field.response}
        onChange={(e) => {
          // console.log(e)
          updateData(id, "response", e);
        }}
        TextFieldsProps={{
          variant: field.variant,
          //TODO :  add a typography to display error message instead of helper text
          // helperText: field.errorMsg,
          error: !!field.errorMsg,
        }}
        //  helperText= {"ghjkhkjhkh"}
      />
    </>
  );
};
//
export default CustomOtp;
