import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  severity: "",
  message: "",
};

export const ToasterSlice = createSlice({
  name: "toaster",
  initialState,
  reducers: {
    addData: (state, action) => {
      state.severity = action.payload.severity;
      state.message = action.payload.message;
      state.open = action.payload.open;
    },
  },
});

export const { addData } = ToasterSlice.actions;
export default ToasterSlice.reducer;
