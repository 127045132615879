// import { useDispatch } from "react-redux";

export const validator = (value, validation) => {
  // value = value?.trim();
  if (!value) {
    return "This field is required";
  }
  value = typeof value === "string" ? value.trim() : value;
  if (value.length < validation.length.value) {
    return validation.length.message;
  }
  // console.log(RegExp(validation.pattern.value).test(value),RegExp(validation.pattern.value))
  if (!RegExp(validation.pattern.value).test(value)) {
    return validation.pattern.message;
  }
};

export const DataPrepare = (store) => {
  let temp = {};
  for (let i of store) {
    temp[i["label"]] = i["response"];
  }
  return temp;
};

export const LoginButton = (store, addData, dispatch) => {
  // const dispatch=useDispatch()
  let errTemp = "";
  for (let i of store) {
    const value = validator(i["response"], i["validation"]);
    dispatch(
      addData({
        id: i["id"],
        value,
        label: "errorMsg",
      })
    );
    if (value) {
      errTemp = true;
    }
  }
  if (errTemp) {
    return;
  }
  // CallServer();  //async call
};

export const validatechar = (value) => {
  // console.log(value)
  if ("0" <= value && value <= "9") {
    // console.log("hio")
    return true;
  }
  return false;
};
