export const SignUpJson = {
  secData: {
    isOtpVerified: false,
  },
  primaryData: [
    {
      inputType: "typography",
      style: { textAlign: "center" },
      label: "SIGNUP",
      id: 0,
    },
    {
      label: "Name",
      field: "firstName",
      placeholder: "Enter your Name",
      response: "",
      size: "medium",
      type: "text",
      extraResponse: "",
      required: true,
      inputType: "customTextField",
      isValidInputField: false,
      errorMsg: "",
      inputProps: "",
      id: 1,
      validation: {
        pattern: {
          value: "^[a-zA-Z][a-zA-Z\\s]*$",
          message: "Name should consists of only characters",
        },
        length: {
          value: 3,
          message: "name should be atleast 3 characters",
        },
      },
      buttonsToValidate: ["signup"],
    },
    {
      label: "Email",
      field: "email",
      placeholder: "Enter your Email",
      response: "",
      required: true,
      size: "medium",
      type: "text",
      inputType: "customTextField",
      isValidInputField: false,
      extraResponse: "",
      errorMsg: "",
      inputProps: "",
      id: 2,
      buttonsToValidate: ["sendCode"],
      validation: {
        pattern: {
          value: "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
          message: "Enter a valid Email",
        },
        length: {
          value: 0,
          message: "email should be atleast 3 characters",
        },
      },
    },
    {
      label: "Phone Number",
      field: "mobileNumber",
      placeholder: "Enter your Phone Number",
      response: "",
      size: "medium",
      type: "numeric",
      buttonsToValidate: ["signup"],
      inputType: "customTextField",
      isValidInputField: false,
      extraResponse: "",
      errorMsg: "",
      inputProps: {
        maxlength: 10,
      },
      id: 3,
      validation: {
        pattern: {
          value: "^[6-9]\\d{9}$",
          message: "Phone Number should have only numeric characters",
        },
        length: {
          value: 10,
          message: "Phone Number should have 10 characters",
        },
      },
    },
    {
      label: "Password",
      field: "password",
      type: "password",
      required: true,
      placeholder: "Enter your Password",
      response: "",
      buttonsToValidate: ["signup"],
      // type: "text",
      size: "medium",
      inputType: "customTextField",
      isValidInputField: false,
      extraResponse: true,
      errorMsg: "",
      inputProps: "password",
      id: 4,
      validation: {
        pattern: {
          value: "^(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*?&#^])(?=.*\\d).*",
          message:
            "Password should consists of atleast one upper, lower, number and a special character",
        },
        length: {
          value: 8,
          message: "Password should be of atleast 8 characters",
        },
      },
    },
    {
      label: "Send Code",
      field: "sendCode",
      variant: "contained",
      buttonId: "sendCode",
      type: "button",
      title: "Enter Email To Send Code",
      style: {
        "&.Mui-disabled": {
          pointerEvents: "all",
        },
      },
      inputType: "button",
      isButtonDisabled: true,
      id: 5,
    },
    {
      length: 6,
      label: "otp",
      field: "otp",
      response: "",
      required: true,
      inputType: "customOtpField",
      isValidInputField: false,
      extraResponse: "",
      errorMsg: "",
      variant: "standard",
      id: 6,
      buttonsToValidate: ["signup", "verifyEmail"],
      isOtpField: true,
      focus: false,
      validation: {
        pattern: {
          value: "^\\d{6}$",
          message: "Invalid otp",
        },
        length: {
          value: 0,
          message: "",
        },
      },
    },
    {
      label: "Verify Email",
      field: "verifyEmail",
      buttonId: "verifyEmail",
      variant: "contained",
      type: "button",
      style: {
        "&.Mui-disabled": {
          pointerEvents: "all",
        },
      },
      title: "Enter Code To Verify",
      inputType: "button",
      isButtonDisabled: true,
      id: 7,
    },
    {
      label: "Forgot Password",
      inputType: "link",
      id: 8,
      style: { textDecoration: "none", textAlign: "center" },
      to: "/forgotpassword",
    },
    {
      label: "signup",
      variant: "contained",
      id: 9,
      buttonId: "signup",
      field: "signup",
      title: "Enter All Required Fileds To Signup",
      style: {
        "&.Mui-disabled": {
          pointerEvents: "all",
        },
      },
      isButtonDisabled: true,
      type: "button",
      inputType: "button",
    },
  ],
};
