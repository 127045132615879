import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingStatus: false,
};

export const LoaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loadingStatus = action.payload.value;
    },
  },
});

export const { setLoading } = LoaderSlice.actions;

export default LoaderSlice.reducer;
