import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addData } from "./redux/reducers/ToasterSlice";

const Toaster = () => {
  const store = useSelector((state) => state.toaster);
  const dispatch = useDispatch();
  //   console.log(store)
  const handleClose = () => {
    dispatch(
      addData({
        open: false,
        message: "",
        severity: "",
      })
    );
  };
  return (
    <Snackbar
      open={store.open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <Alert severity={store.severity} onClose={handleClose}>
        {store.message}
      </Alert>
    </Snackbar>
  );
};

export default Toaster;
