import { Box } from "@mui/material";
import React from "react";
import { setLoading } from "../redux/reducers/LoaderSlice";
import { Get, Post } from "../service/api.service";
import { useDispatch, useSelector } from "react-redux";
import { addData, clearPrimaryData } from "../redux/reducers/EmailPageSlice";
import { addData as userAddData } from "../redux/reducers/UserSlice";
import { addData as toasterAddData } from "../redux/reducers/ToasterSlice";
import ComponentsByType from "../ComponentsByType";
import { useNavigate } from "react-router-dom";

import * as _ from "lodash";
const EmailPage = () => {
  const store = useSelector((state) => state.emailPage.EmailPageJson);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = (buttonId) => {
    if (buttonId === "sendCode") {
      // console.log('buttonId clicked',buttonId);
      sendCode();
    }
  };

  const sendCode = async () => {
    dispatch(setLoading({ value: true }));
    const otpMode = "email";
    let obj = {
      type: otpMode,
    };
    const respVal = _.find(store?.primaryData, { field: "email" })?.response;
    obj[`${otpMode}`] = respVal;
    dispatch(userAddData({ key: "email", value: respVal }));

    const resp = await Post("authentication/sendCode", obj);
    if (resp?.success) {
      dispatch(setLoading({ value: false }));
      dispatch(clearPrimaryData({}));
      navigate("/verifyOtp");
      console.log(`Verification code sent to ${otpMode} successfully`);
    } else {
      dispatch(setLoading({ value: false }));
      dispatch(
        toasterAddData({
          open: true,
          severity: "error",
          message: resp?.message,
        })
      );
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 2,
        }}
      >
        {store?.primaryData?.map((field) => (
          <ComponentsByType
            field={field}
            id={field.id}
            addData={addData}
            storeKey={"emailPage.EmailPageJson"}
            fieldKey={field.id}
            handleSubmit={handleSubmit}
          />
        ))}
      </Box>
    </Box>
  );
};

export default EmailPage;
