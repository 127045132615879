import { Google } from "@mui/icons-material";
import { Button } from "@mui/material";
import parse from "html-react-parser";

import React from "react";
const CustomButton = (props) => {
  const { field, handleSubmit } = props;
  const handleButtonClick = (buttonId) => {
    console.log(`${buttonId} clicked....`);
    if (buttonId === "googleLogin") {
      window.location.href =
        "https://dev.inviteall.tech/api/authentication/google/";
    } else {
      handleSubmit(buttonId);
    }
  };
  const componentMap = {
    Google: Google,
  };
  let DynamicComponent;
  if (field.startIcon?.type === "component") {
    DynamicComponent = componentMap[field.startIcon?.value];
  }

  return (
    <Button
      id={field.id}
      variant={field.variant}
      disabled={field.isButtonDisabled}
      startIcon={DynamicComponent ? <DynamicComponent /> : field.startIcon}
      onClick={() => {
        handleButtonClick(field.buttonId);
      }}
      sx={field.style}
      title={field.title}
    >
      {field.label}
    </Button>
  );
};

export default CustomButton;
