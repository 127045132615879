//This is a global button mapper and has unique buttonId as a key and array which contains fields or values required
//buttonId will be unique across application
const buttonMapper = {
  signup: [
    { type: "field", value: "firstName" },
    { type: "field", value: "email" },
    { type: "field", value: "mobileNumber" },
    { type: "field", value: "password" },
    { type: "stateVariable", value: "isOtpVerified" },
  ],
  // 'signup':[],
  sendCode: [{ type: "field", value: "email" }],
  verifyEmail: [{ type: "field", value: "otp" }],
  login: [
    { type: "field", value: "email" },
    { type: "field", value: "password" },
  ],
  updatePassword: [
    { type: "field", value: "password" },
    { type: "field", value: "confirmPassword" },
  ],
};

export default buttonMapper;
