import { AppBar,  Container, Toolbar } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const navlinks = [
    { path: "home", text: "Home" },
    { path: "abc", text: "ABC" },
    { path: "contacts", text: "Contacts" },
  ];
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar>
          <ul
            style={{
              display: "flex",
              gap:"30px",
              listStyleType:'none',
              p:0
            }}
          >
            {navlinks.map((linkData) => (
              <li style={{}}>
                <NavLink to={linkData.path} style={({isActive})=>{
                  return{
                    textDecoration:'none',fontSize:20,
                    color:isActive?'black':'white',
                    backgroundColor:isActive?'grey':'',
                  }
                }}>
                  {linkData.text}
                </NavLink>
              </li>
            ))}
          </ul>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
