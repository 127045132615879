export const OtpJson = {
  secData: {},
  primaryData: [
    {
      inputType: "typography",
      style: { textAlign: "center" },
      label: "Verify Email",
      id: 0,
    },
    {
      length: 6,
      label: "otp",
      field: "otp",
      response: "",
      required: true,
      inputType: "customOtpField",
      isValidInputField: false,
      extraResponse: "",
      errorMsg: "",
      variant: "standard",
      id: 1,
      buttonsToValidate: ["verifyEmail"],
      isOtpField: true,
      focus: false,
      validation: {
        pattern: {
          value: "^\\d{6}$",
          message: "Invalid otp",
        },
        length: {
          value: 0,
          message: "",
        },
      },
    },
    {
      label: "Verify Email",
      field: "verifyEmail",
      buttonId: "verifyEmail",
      variant: "contained",
      type: "button",
      style: {
        "&.Mui-disabled": {
          pointerEvents: "all",
        },
      },
      title: "Enter Code To Verify",
      inputType: "button",
      isButtonDisabled: true,
      id: 2,
    },
  ],
};
