import axios, { isCancel, AxiosError } from "axios";

const baseServerUrl =
  process.env.REACT_APP_API_URL || "https://dev.inviteall.tech/api/";
// const baseServerUrl =  'http://127.0.0.1:4000/api/';

// let that = {}
export const Get = async (path, params) => {
  try {
    // console.log("ss",process.env.REACT_APP_API_URL)
    const url = `${baseServerUrl}${path}`;
    const options = { withCredentials: true };
    const resp = await axios.get(url, options);
    return respHandler(resp);
  } catch (error) {
    const resp = error?.response?.data || error;
    return {
      success: false,
      statusCode: resp?.status,
      message: resp?.message || resp?.msg,
    };
  }
};
// export default Get;

export const Post = async (path, body, params) => {
  try {
    const url = `${baseServerUrl}${path}`;
    const options = {
      isJson: true,
      withCredentials: true,
    };
    const resp = await axios.post(url, body, options);
    return respHandler(resp);
  } catch (error) {
    const resp = error?.response?.data || error;
    return {
      success: false,
      statusCode: resp?.status,
      message: resp?.message || resp?.msg,
    };
  }
};
export const Put = (url, body, params) => {};
export const Delete = (url) => {};

const respHandler = (resp) => {
  if (resp.status >= 400) {
    resp.success = false;
  } else {
    resp.success = true;
    // resp. redirect  = true
  }
  return resp;
};
// that = {
//     Get,
//     Post,
//     Put,
//     Delete
// }
// export default Get
