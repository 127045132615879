import { createSlice } from "@reduxjs/toolkit";
import { ChangePasswordJson } from "../../config/ChangePassword";
import * as _ from "lodash";
const initialState = {
  changePasswordJson: ChangePasswordJson,
};

export const ChangePasswordSlice = createSlice({
  name: "change password",
  initialState,
  reducers: {
    addData: (state, action) => {
      let { key, value, field, id, label } = action.payload;
      if (!key) {
        key = label;
      }
      if (id !== undefined) {
        if (key) {
          //update only specific key
          state.changePasswordJson.primaryData[id][key] = value;
        } else {
          //to update complete obj if no key specified
          // state.LoginJson.primaryData[id] = {...value,...state.LoginJson.primaryData[id]};
          state.changePasswordJson.primaryData[id] = _.mergeWith(
            state.changePasswordJson.primaryData[id],
            value
          );
        }
      } else if (field) {
        state.changePasswordJson.primaryData.map((d) => {
          if (d.field === field) {
            if (key) {
              //update only specific key
              d[key] = value;
            } else {
              //to update complete obj if no key specified
              // d = {...value,...d}
              d = _.mergeWith(d, value);
            }
          }
        });
      }
    },
    //TODO :  later change this to update all types of data currently only object is supported
    updateSecData: (state, action) => {
      let { key, value } = action.payload;
      state.changePasswordJson.secData[key] = value;
    },
    //This is to clear form data
    clearPrimaryData: (state, action) => {
      state.changePasswordJson.primaryData = ChangePasswordJson.primaryData;
    },
  },
});

export const { addData, updateSecData, clearPrimaryData } =
  ChangePasswordSlice.actions;
export default ChangePasswordSlice.reducer;
