import { createSlice } from "@reduxjs/toolkit";

import * as _ from "lodash";
const initialState = {};
//Info This slice is a userObj a json object
export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addData: (state, action) => {
      let { key, value, label } = action.payload;
      if (!key) {
        key = label;
      }

      if (key) {
        //update only specific key
        state[key] = value;
      } else {
        //to update complete obj if no key specified
        state = value;
      }
    },
    //This is to clear form data
    clearData: (state, action) => {
      state = initialState;
    },
  },
});

export const { addData } = UserSlice.actions;
export default UserSlice.reducer;
