import React from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { addData, clearPrimaryData } from "../redux/reducers/OtpSlice";
import { addData as userAddData } from "../redux/reducers/UserSlice";
import { addData as toasterAddData } from "../redux/reducers/ToasterSlice";
import { setLoading } from "../redux/reducers/LoaderSlice";
import { Get, Post } from "../service/api.service";
import { useNavigate } from "react-router-dom";

import * as _ from "lodash";
import ComponentsByType from "../ComponentsByType";
const OTP = () => {
  const store = useSelector((state) => state.otpPage.OtpJson);
  const userStore = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (buttonId) => {
    if (buttonId === "verifyEmail") {
      verifyCode();
    }
  };
  const verifyCode = async () => {
    dispatch(setLoading({ value: true }));
    const otpMode = "email";
    let obj = {};

    const respVal = userStore?.email;
    if (!respVal) {
      dispatch(setLoading({ value: false }));
      navigate("/forgotpassword");
      dispatch(clearPrimaryData({}));
    }
    obj[`${otpMode}`] = respVal;
    obj["code"] = _.find(store?.primaryData, { field: "otp" })?.response;

    const resp = await Post("authentication/verifyCode", obj);
    if (resp?.success) {
      dispatch(setLoading({ value: false }));
      dispatch(userAddData({ key: "isOtpVerified", value: true }));
      navigate("/changePassword");
      dispatch(clearPrimaryData({}));
      console.log(`Verification code sent to ${otpMode} successfully`);
    } else {
      dispatch(setLoading({ value: false }));
      dispatch(
        toasterAddData({
          open: true,
          severity: "error",
          message: resp?.message,
        })
      );
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 2,
          width: 300,
        }}
      >
        {store?.primaryData?.map((field) => (
          <ComponentsByType
            field={field}
            id={field.id}
            addData={addData}
            storeKey={"otpPage.OtpJson"}
            fieldKey={field.id}
            handleSubmit={handleSubmit}
          />
        ))}
      </Box>
    </Box>
  );
};

export default OTP;
