import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Routes from "./Paths";


const queryclient=new QueryClient()


function App() {
  return (
    <QueryClientProvider client={queryclient}>
      <Routes />
    </QueryClientProvider>
  );
}

export default App;
