import { configureStore } from "@reduxjs/toolkit";
import LoginSlice from "../reducers/LoginSlice";
import SignUpSlice from "../reducers/SignUpSlice";
import EmailPageSlice from "../reducers/EmailPageSlice";
import OtpSlice from "../reducers/OtpSlice";
import ChangePasswordSlice from "../reducers/ChangePasswordSlice";
import ToasterSlice from "../reducers/ToasterSlice";
import LoaderSlice from "../reducers/LoaderSlice";
import UserSlice from "../reducers/UserSlice";

export const store = configureStore({
  reducer: {
    login: LoginSlice,
    signup: SignUpSlice,
    emailPage: EmailPageSlice,
    otpPage: OtpSlice,
    changePassword: ChangePasswordSlice,
    toaster: ToasterSlice,
    loader: LoaderSlice,
    user: UserSlice,
  },
});
