import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTextField from "./CustomTextField";
import { validator } from "./GlobalFunctions";
import { addData as toasterAddData } from "./redux/reducers/ToasterSlice";
import { addData, clearPrimaryData } from "./redux/reducers/LoginSlice";
import { setLoading } from "./redux/reducers/LoaderSlice";
import { Link, useNavigate } from "react-router-dom";
import { Post, Get } from "./service/api.service";
import ComponentsByType from "./ComponentsByType";
const LoginPage = () => {
  let navigate = useNavigate();

  const store = useSelector((state) => state.login.LoginJson);
  const dispatch = useDispatch();

  const login = async (store) => {
    dispatch(setLoading({ value: true }));
    // const navigate = useNavigate();
    //TODO:  start lodae
    let obj = {};
    store.map((s) => {
      obj[`${s.field}`] = s.response;
    });

    const resp = await Post("authentication/login", obj);
    if (resp?.success) {
      dispatch(setLoading({ value: false }));
      if (resp.data?.redirect) {
        const path = "/navbar/abc";
        navigate(path);
        dispatch(clearPrimaryData({}));
      }
      console.log(`login succesfully and redirected user----- >`);
    } else {
      dispatch(setLoading({ value: false }));
      dispatch(
        toasterAddData({
          open: true,
          severity: "error",
          message: resp?.message,
        })
      );
      console.log(`Error in api call`, resp);
    }
  };
  const handleSubmit = () => {
    console.log(`Clicked login button`);
    login(store?.primaryData);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 2,
        }}
      >
        {store?.primaryData.map((field) => {
          return (
            <ComponentsByType
              field={field}
              id={field.id}
              addData={addData}
              storeKey={"login.LoginJson"}
              handleSubmit={handleSubmit}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default LoginPage;
