import React from "react";
import CustomTextField from "./CustomTextField";
import CustomButton from "./StandAloneComponents/CustomButton";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CustomOtp from "./CustomOtp";

const ComponentsByType = (props) => {
  const { field } = props;
  switch (field.inputType) {
    case "customTextField":
      return <CustomTextField {...props} />;

    case "button":
      return <CustomButton {...props} />;
    case "typography":
      return <Typography sx={field.style}>{field.label}</Typography>;
    case "link":
      return (
        <Link style={field.style} to={field.to}>
          {field.label}
        </Link>
      );
    case "customOtpField":
      return <CustomOtp {...props} />;

    default:
      return <h1>Hello check ur Input Type</h1>;
  }
};

export default ComponentsByType;
