export const LoginJson = {
  secData: {},
  primaryData: [
    {
      inputType: "typography",
      style: { textAlign: "center" },
      label: "LOGIN",
      id: 0,
    },
    {
      label: "Email",
      field: "email",
      placeholder: "Enter your Email",
      response: "",
      size: "medium",
      type: "text",
      extraResponse: "",
      errorMsg: "",
      inputProps: "",
      inputType: "customTextField",
      buttonsToValidate: ["login"],
      id: 1,
      validation: {
        pattern: {
          value: "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
          message: "Enter a valid Email",
        },
        length: {
          value: 0,
          message: "email should be atleast 3 characters",
        },
      },
    },
    {
      label: "Password",
      field: "password",
      placeholder: "Enter your Password",
      response: "",
      type: "password",
      size: "medium",
      extraResponse: true,
      errorMsg: "",
      inputType: "customTextField",
      inputProps: "password",
      buttonsToValidate: ["login"],
      id: 2,
      validation: {
        pattern: {
          value: "^(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*?&#^])(?=.*\\d).*",
          message:
            "Password should consists of atleast one upper, lower, number and a special character",
        },
        length: {
          value: 8,
          message: "Password should be of atleast 8 characters",
        },
      },
    },
    {
      label: "login",
      variant: "contained",
      id: 3,
      buttonId: "login",
      field: "login",
      title: "Enter All Required Fileds To Login",
      style: {
        "&.Mui-disabled": {
          pointerEvents: "all",
        },
      },
      isButtonDisabled: true,
      type: "button",
      inputType: "button",
    },
    {
      label: "Forgot Password ?",
      inputType: "link",
      id: 4,
      style: { textDecoration: "none", textAlign: "center" },
      to: "/forgotpassword",
    },
    {
      label: "SignUp",
      inputType: "link",
      id: 5,
      style: { textDecoration: "none", textAlign: "center" },
      to: "/signup",
    },
    {
      label: "Log in with google",
      startIcon: { type: "component", value: "Google" },
      variant: "contained",
      id: 6,
      buttonId: "googleLogin",
      field: "googleLogin",
      title: "Loginwith Google",
      style: {
        "&.Mui-disabled": {
          pointerEvents: "all",
        },
      },
      type: "button",
      inputType: "button",
    },
  ],
};
