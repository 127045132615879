export const ChangePasswordJson = {
  secData: {},
  primaryData: [
    {
      inputType: "typography",
      style: { textAlign: "center" },
      label: "Set New Password",
      id: 0,
    },
    {
      label: "NewPassword",
      placeholder: "Enter your Password",
      response: "",
      type: "password",
      size: "medium",
      errorMsg: "",
      inputType: "customTextField",
      field: "password",
      isValidInputField: false,
      extraResponse: true,
      inputProps: "password",
      id: 1,
      buttonsToValidate: ["updatePassword"],
      validation: {
        pattern: {
          value: "^(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*?&#^])(?=.*\\d).*",
          message:
            "Password should consists of atleast one upper, lower, number and a special character",
        },
        length: {
          value: 8,
          message: "Password should be of atleast 8 characters",
        },
      },
    },
    {
      label: "ConfirmPassword",
      placeholder: "Confirm your Password",
      response: "",
      type: "password",
      size: "medium",
      field: "confirmPassword",
      inputType: "customTextField",
      isValidInputField: false,
      extraResponse: true,
      errorMsg: "",
      inputProps: "password",
      id: 2,
      compareWith: true,
      fieldToCheck: "password",
      buttonsToValidate: ["updatePassword"],
      validation: {
        pattern: {
          // value: "^(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*?&#^])(?=.*\\d).*",
          value: "",
          message: "",
        },
        length: {
          value: 0,
          message: "Password should be of atleast 8 characters",
        },
      },
    },
    {
      label: "Update",
      variant: "contained",
      id: 3,
      buttonId: "updatePassword",
      field: "updatePassword",
      title: "Enter All Required Fileds To Signup",
      style: {
        "&.Mui-disabled": {
          pointerEvents: "all",
        },
      },
      isButtonDisabled: true,
      type: "button",
      inputType: "button",
    },
  ],
};
