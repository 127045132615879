import React, { useEffect, useState } from "react";
import { Get, Post } from "./service/api.service";
// import Loader from './Loader';
import { useNavigate } from "react-router-dom";
import { setLoading } from "./redux/reducers/LoaderSlice";
import { useDispatch } from "react-redux";
const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  async function getUserData() {
    dispatch(setLoading({ value: true }));
    const resp = await Get("users/currentUser");
    if (resp?.success && resp?.data) {
      dispatch(setLoading({ value: false }));
      setUser(resp.data);
    } else {
      dispatch(setLoading({ value: false }));
      setUser(undefined);
      console.log("user in false resp", user);
    }
    console.log("resp", resp);
  }

  const handleLogout = async () => {
    dispatch(setLoading({ value: true }));
    console.log("on handle logout");
    const resp = await Post("authentication/logout");
    if (resp?.success && resp?.data) {
      setUser(undefined);
      dispatch(setLoading({ value: false }));
    } else {
      dispatch(setLoading({ value: false }));
    }
  };
  const handleLogin = () => {
    console.log("login button clicked ....");
    navigate("/login");
  };
  const handleRefresh = () => {
    console.log("login button clicked ....");
    getUserData();
  };
  useEffect(() => {
    getUserData();
  }, []);
  return (
    <>
      {/* <Loader open={isLoading} /> */}
      {!user || !user._id ? (
        <div>
          <button onClick={handleRefresh}>Refresh</button>
          <br></br>
          <br></br>
          <button onClick={handleLogin}>Login</button>
          <br></br>
          <br></br>
          <button onClick={getUserData}>Get user info</button>
        </div>
      ) : (
        <div>
          <button onClick={handleRefresh}>Refresh</button>
          <br></br>
          <h3>Email: {user.email}</h3>
          <h3>Name: {user.firstName}</h3>
          <button onClick={handleLogout}>Logout</button>
        </div>
      )}

      <footer style={{
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    color: 'black',
    textAlign: 'center',
    padding: '10px 0',
  }}>
        V : {require("../package.json").version}
      </footer>
    </>
  );
};

export default HomePage;
